<template>
  <div class="content-wrapper">
    <bo-page-header></bo-page-header>
    <div class="content pt-0">
      <b-card body-class="d-md-flex align-items-md-center justify-content-md-between flex-md-wrap">
        <div class="d-flex align-items-center mb-3 mb-md-0">
          <div class="btn-group">
            <button type="button" class="btn bg-indigo-400"><i class="icon-file-pdf mr-2"></i> Export PDF</button>
          </div>
        </div>
        <div class="d-flex align-items-center mb-3 mb-md-0">
          <div class="ml-3">
            <div class="input-group">
              <span class="input-group-prepend">
                <span class="input-group-text"><i class="icon-calendar22"></i></span>
              </span>
              <date-range-picker class="form-control" control-container-class="" opens="left" append-to-body
                :date-range="dateRange" style="min-width: 180px" />
            </div>
          </div>
        </div>
      </b-card>
      <b-row>
        <b-col md="3">
          <b-card>
            <div class="d-flex align-items-center justify-content-center mb-2">
              <div>
                <div class="font-weight-semibold"> <span class="badge badge-mark border-teal mr-1"></span> Total
                  Mentions</div>
                <p class="total_mention_txt">2,349 </p>
              </div>
            </div>
            <sparkline :indicatorStyles="spIndicatorStyles1" class="w-75 mx-auto">
              <sparklineLine :data="spData1" :limit="spData1.length" :styles="spLineStyles1" />
            </sparkline>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card>
            <div class="d-flex align-items-center justify-content-center mb-2">
              <div>
                <div class="font-weight-semibold"><span class="badge badge-mark border-blue mr-1"></span> Positive
                  Mentions</div>
                <p class="total_mention_txt">120</p>
              </div>
            </div>
            <sparkline :indicatorStyles="spIndicatorStyles1" class="w-75 mx-auto">
              <sparklineLine :data="spData1" :limit="spData1.length" :styles="spLineStyles1" />
            </sparkline>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card>
            <div class="d-flex align-items-center justify-content-center mb-2">
              <div>
                <div class="font-weight-semibold"><span class="badge badge-mark border-danger mr-1"></span> Negative
                  Mentions</div>
                <p class="total_mention_txt"> 5,378</p>
              </div>
            </div>
            <sparkline :indicatorStyles="spIndicatorStyles1" class="w-75 mx-auto">
              <sparklineLine :data="spData1" :limit="spData1.length" :styles="spLineStyles1" />
            </sparkline>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card>
            <div class="d-flex align-items-center justify-content-center mb-2">
              <div>
                <div class="font-weight-semibold"><span class="badge badge-mark border-indigo mr-1"></span> Socmed
                  Mentions</div>
                <p class="total_mention_txt">5,378</p>
              </div>
            </div>
            <sparkline :indicatorStyles="spIndicatorStyles1" class="w-75 mx-auto">
              <sparklineLine :data="spData1" :limit="spData1.length" :styles="spLineStyles1" />
            </sparkline>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card>
            <div class="d-flex align-items-center justify-content-center mb-2">
              <div>
                <div class="font-weight-semibold"><span class="badge badge-mark border-green mr-1"></span> Non
                  Socmed Mentions</div>
                <p class="total_mention_txt">378</p>
              </div>
            </div>
            <sparkline :indicatorStyles="spIndicatorStyles1" class="w-75 mx-auto">
              <sparklineLine :data="spData1" :limit="spData1.length" :styles="spLineStyles1" />
            </sparkline>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card>
            <div class="d-flex align-items-center justify-content-center mb-2">
              <div>
                <div class="font-weight-semibold"><span class="badge badge-mark border-warning mr-1"></span>
                  Estimated Socmed Mentions</div>
                <p class="total_mention_txt">5,378</p>
              </div>
            </div>
            <sparkline :indicatorStyles="spIndicatorStyles1" class="w-75 mx-auto">
              <sparklineLine :data="spData1" :limit="spData1.length" :styles="spLineStyles1" />
            </sparkline>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card>
            <div class="d-flex align-items-center justify-content-center mb-2">
              <div>
                <div class="font-weight-semibold"><span class="badge badge-mark border-indigo mr-1"></span> Socmed
                  Likes</div>
                <p class="total_mention_txt">5,378 avg</p>
              </div>
            </div>
            <sparkline :indicatorStyles="spIndicatorStyles1" class="w-75 mx-auto">
              <sparklineLine :data="spData1" :limit="spData1.length" :styles="spLineStyles1" />
            </sparkline>
          </b-card>
        </b-col>
        <b-col md="3">
          <b-card>
            <div class="d-flex align-items-center justify-content-center mb-2">
              <div>
                <div class="font-weight-semibold"><span class="badge badge-mark border-danger mr-1"></span> Socmed
                  Shares</div>
                <p class="total_mention_txt">5,378</p>
              </div>
            </div>
            <sparkline :indicatorStyles="spIndicatorStyles1" class="w-75 mx-auto">
              <sparklineLine :data="spData1" :limit="spData1.length" :styles="spLineStyles1" />
            </sparkline>
          </b-card>
        </b-col>
      </b-row>
      <b-card no-body header-class="header-elements-sm-inline">
        <template #header>
          <b-card-title title-tag="h6" class="font-weight-bold">Source</b-card-title>
        </template>
        <b-table :fields="sourceMentionFields" :items="sourceMentionDatas" bordered responsive :per-page="5"
          :current-page="sourceMentionPage">
          <template #cell(num)="data">
            {{ data.index += 1 + (sourceMentionPage - 1)*5  }}
          </template>
          <template #cell(username)="data">
            <div class="d-flex align-items-center">
              <div class="mr-3">
                <template v-if="data.value.type == 'instagram'">
                  <a href="#" class="btn bg_ig rounded-round btn-icon btn-sm">
                    <i class="icon-instagram"></i>
                  </a>
                </template>
                <template v-else-if="data.value.type == 'facebook'">
                  <a href="#" class="btn bg_fb rounded-round btn-icon btn-sm">
                    <i class="icon-facebook"></i>
                  </a>
                </template>
                <template v-else-if="data.value.type == 'twitter'">
                  <a href="#" class="btn bg_tw rounded-round btn-icon btn-sm">
                    <i class="icon-twitter"></i>
                  </a>
                </template>
              </div>
              <div>
                <a href="#" class="text-default font-weight-semibold letter-icon-title">{{ data.value.name }}</a>
              </div>
            </div>
          </template>
          <template #cell(mention)="data">
            <strong>{{ data.value }}</strong>
          </template>
          <template #cell(reach)="data">
            <strong>{{ data.value }}</strong>
          </template>
          <template #cell(influence_value)="data">
            <div class="rating_score" v-html="starMaker(5, data.value)" />
          </template>
        </b-table>
        <template #footer>
          <b-pagination :per-page="5" :total-rows="sourceMentionPage.length" class="pagination-flat"
            v-model="sourceMentionPage" hide-goto-end-buttons align="end">
            <template #prev-text>&larr; &nbsp; Prev</template>
            <template #next-text>Next &nbsp; &rarr;</template>
          </b-pagination>
        </template>
      </b-card>
      <b-tabs nav-class="nav-tabs-top bg_tab" justified>
        <b-tab title="Most Popular Mentions">
          <div class="card_mention">
            <div class="row">
              <div class="col-md-3">
                <div class="d-flex align-items-center">
                  <div class="mr-3">
                    <a href="#" class="btn bg_fb  rounded-round btn-icon btn-sm">
                      <i class="icon-facebook"></i>
                    </a>
                  </div>
                  <div>
                    <a href="#" class="text-default font-weight-bold letter-icon-title">@integradesign</a>
                    <div class="text-muted font-size-sm"><span class="badge badge-mark border-blue mr-1"></span>
                      Active</div>
                  </div>
                </div>
              </div>
              <div class="col-md-7">
                <div class="card_content">
                  <h3><a href="">lamaran sebagai iOS Developer (3284527)</a></h3>
                  <h4><a href="">facebbok.com</a></h4>
                  <p>
                    lamaran sebagai iOS Developer (3284527) di WEBARQ (PT Web Architect Technology)! Cari lowongan
                    kerja
                  </p>
                </div>
              </div>
              <div class="col-md-2">
                <a href="#" class="text-default">
                  <span class="text-muted"><i class="icon-calendar mr-2"></i> 21 Sep 2020, 15:00</span>
                </a>
                <div class="row">
                  <div class="col-md-12 mt-3">
                    <div class="total_share">
                      <ul>
                        <li><a href=""><i class="icon-share3"></i> 1,383 </a></li>
                        <li><a href=""><i class="icon-thumbs-up2"></i> 1,383 </a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card_mention">
            <div class="row">
              <div class="col-md-3">
                <div class="d-flex align-items-center">
                  <div class="mr-3">
                    <a href="#" class="btn bg_ig  rounded-round btn-icon btn-sm">
                      <i class="icon-instagram"></i>
                    </a>
                  </div>
                  <div>
                    <a href="#" class="text-default font-weight-bold letter-icon-title">@integradesign</a>
                    <div class="text-muted font-size-sm"><span class="badge badge-mark border-blue mr-1"></span>
                      Active</div>
                  </div>
                </div>
              </div>
              <div class="col-md-7">
                <div class="card_content">
                  <h3><a href="">lamaran sebagai iOS Developer (3284527)</a></h3>
                  <h4><a href="">instagram.com</a></h4>
                  <p>
                    lamaran sebagai iOS Developer (3284527) di WEBARQ (PT Web Architect Technology)! Cari lowongan
                    kerja
                  </p>
                </div>
              </div>
              <div class="col-md-2">
                <a href="#" class="text-default">
                  <span class="text-muted"><i class="icon-calendar mr-2"></i> 21 Sep 2020, 15:00</span>
                </a>
                <div class="row">
                  <div class="col-md-12 mt-3">
                    <div class="total_share">
                      <ul>
                        <li><a href=""><i class="icon-share3"></i> 1,383 </a></li>
                        <li><a href=""><i class="icon-thumbs-up2"></i> 1,383 </a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card_mention">
            <div class="row">
              <div class="col-md-3">
                <div class="d-flex align-items-center">
                  <div class="mr-3">
                    <a href="#" class="btn bg_tw  rounded-round btn-icon btn-sm">
                      <i class="icon-twitter"></i>
                    </a>
                  </div>
                  <div>
                    <a href="#" class="text-default font-weight-bold letter-icon-title">@integradesign</a>
                    <div class="text-muted font-size-sm"><span class="badge badge-mark border-blue mr-1"></span>
                      Active</div>
                  </div>
                </div>
              </div>
              <div class="col-md-7">
                <div class="card_content">
                  <h3><a href="">lamaran sebagai iOS Developer (3284527)</a></h3>
                  <h4><a href="">twitter.com</a></h4>
                  <p>
                    lamaran sebagai iOS Developer (3284527) di WEBARQ (PT Web Architect Technology)! Cari lowongan
                    kerja
                  </p>
                </div>
              </div>
              <div class="col-md-2">
                <a href="#" class="text-default">
                  <span class="text-muted"><i class="icon-calendar mr-2"></i> 21 Sep 2020, 15:00</span>
                </a>
                <div class="row">
                  <div class="col-md-12 mt-3">
                    <div class="total_share">
                      <ul>
                        <li><a href=""><i class="icon-share3"></i> 1,383 </a></li>
                        <li><a href=""><i class="icon-thumbs-up2"></i> 1,383 </a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card_mention">
            <div class="row">
              <div class="col-md-3">
                <div class="d-flex align-items-center">
                  <div class="mr-3">
                    <a href="#" class="btn bg_fb  rounded-round btn-icon btn-sm">
                      <i class="icon-facebook"></i>
                    </a>
                  </div>
                  <div>
                    <a href="#" class="text-default font-weight-bold letter-icon-title">@bobatimeindonesia</a>
                    <div class="text-muted font-size-sm"><span class="badge badge-mark border-blue mr-1"></span>
                      Active</div>
                  </div>
                </div>
              </div>
              <div class="col-md-7">
                <div class="card_content">
                  <h3><a href="">lamaran sebagai iOS Developer (3284527)</a></h3>
                  <h4><a href="">facebook.com</a></h4>
                  <p>
                    lamaran sebagai iOS Developer (3284527) di WEBARQ (PT Web Architect Technology)! Cari lowongan
                    kerja
                  </p>
                </div>
              </div>
              <div class="col-md-2">
                <a href="#" class="text-default">
                  <span class="text-muted"><i class="icon-calendar mr-2"></i> 21 Sep 2020, 15:00</span>
                </a>
                <div class="row">
                  <div class="col-md-12 mt-3">
                    <div class="total_share">
                      <ul>
                        <li><a href=""><i class="icon-share3"></i> 1,383 </a></li>
                        <li><a href=""><i class="icon-thumbs-up2"></i> 1,383 </a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card_mention">
            <div class="row">
              <div class="col-md-3">
                <div class="d-flex align-items-center">
                  <div class="mr-3">
                    <a href="#" class="btn bg_ig  rounded-round btn-icon btn-sm">
                      <i class="icon-instagram"></i>
                    </a>
                  </div>
                  <div>
                    <a href="#" class="text-default font-weight-bold letter-icon-title">@indosuryafinance</a>
                    <div class="text-muted font-size-sm"><span class="badge badge-mark border-blue mr-1"></span>
                      Active</div>
                  </div>
                </div>
              </div>
              <div class="col-md-7">
                <div class="card_content">
                  <h3><a href="">lamaran sebagai iOS Developer (3284527)</a></h3>
                  <h4><a href="">instagram.com</a></h4>
                  <p>
                    lamaran sebagai iOS Developer (3284527) di WEBARQ (PT Web Architect Technology)! Cari lowongan
                    kerja
                  </p>
                </div>
              </div>
              <div class="col-md-2">
                <a href="#" class="text-default">
                  <span class="text-muted"><i class="icon-calendar mr-2"></i> 21 Sep 2020, 15:00</span>
                </a>
                <div class="row">
                  <div class="col-md-12 mt-3">
                    <div class="total_share">
                      <ul>
                        <li><a href=""><i class="icon-share3"></i> 1,383 </a></li>
                        <li><a href=""><i class="icon-thumbs-up2"></i> 1,383 </a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card_mention">
            <div class="row">
              <div class="col-md-3">
                <div class="d-flex align-items-center">
                  <div class="mr-3">
                    <a href="#" class="btn bg_tw  rounded-round btn-icon btn-sm">
                      <i class="icon-twitter"></i>
                    </a>
                  </div>
                  <div>
                    <a href="#" class="text-default font-weight-bold letter-icon-title">@kemilaumarmer</a>
                    <div class="text-muted font-size-sm"><span class="badge badge-mark border-blue mr-1"></span>
                      Active</div>
                  </div>
                </div>
              </div>
              <div class="col-md-7">
                <div class="card_content">
                  <h3><a href="">lamaran sebagai iOS Developer (3284527)</a></h3>
                  <h4><a href="">twitter.com</a></h4>
                  <p>
                    lamaran sebagai iOS Developer (3284527) di WEBARQ (PT Web Architect Technology)! Cari lowongan
                    kerja
                  </p>
                </div>
              </div>
              <div class="col-md-2">
                <a href="#" class="text-default">
                  <span class="text-muted"><i class="icon-calendar mr-2"></i> 21 Sep 2020, 15:00</span>
                </a>
                <div class="row">
                  <div class="col-md-12 mt-3">
                    <div class="total_share">
                      <ul>
                        <li><a href=""><i class="icon-share3"></i> 1,383 </a></li>
                        <li><a href=""><i class="icon-thumbs-up2"></i> 1,383 </a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <b-card>
            <ul class="pagination pagination-flat align-self-center">
              <li class="page-item"><a href="#" class="page-link">&larr; &nbsp; Prev</a></li>
              <li class="page-item active"><a href="#" class="page-link">1</a></li>
              <li class="page-item"><a href="#" class="page-link">2</a></li>
              <li class="page-item disabled"><a href="#" class="page-link">3</a></li>
              <li class="page-item"><a href="#" class="page-link">4</a></li>
              <li class="page-item"><a href="#" class="page-link">Next &nbsp; &rarr;</a></li>
            </ul>
          </b-card>
        </b-tab>
        <b-tab title="Most Influential Source">
          <div class="card_mention">
            <div class="row">
              <div class="col-md-3">
                <div class="d-flex align-items-center">
                  <div class="mr-3">
                    <a href="#" class="btn bg_fb  rounded-round btn-icon btn-sm">
                      <i class="icon-facebook"></i>
                    </a>
                  </div>
                  <div>
                    <a href="#" class="text-default font-weight-bold letter-icon-title">@integradesign</a>
                    <div class="text-muted font-size-sm"><span class="badge badge-mark border-blue mr-1"></span>
                      Active</div>
                  </div>
                </div>
              </div>
              <div class="col-md-7">
                <div class="card_content">
                  <h3><a href="">lamaran sebagai iOS Developer (3284527)</a></h3>
                  <h4><a href="">facebbok.com</a></h4>
                  <p>
                    lamaran sebagai iOS Developer (3284527) di WEBARQ (PT Web Architect Technology)! Cari lowongan
                    kerja
                  </p>
                </div>
              </div>
              <div class="col-md-2">
                <a href="#" class="text-default">
                  <span class="text-muted"><i class="icon-calendar mr-2"></i> 21 Sep 2020, 15:00</span>
                </a>
                <div class="row">
                  <div class="col-md-12 mt-3">
                    <div class="total_share">
                      <ul>
                        <li><a href=""><i class="icon-share3"></i> 1,383 </a></li>
                        <li><a href=""><i class="icon-thumbs-up2"></i> 1,383 </a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card_mention">
            <div class="row">
              <div class="col-md-3">
                <div class="d-flex align-items-center">
                  <div class="mr-3">
                    <a href="#" class="btn bg_ig  rounded-round btn-icon btn-sm">
                      <i class="icon-instagram"></i>
                    </a>
                  </div>
                  <div>
                    <a href="#" class="text-default font-weight-bold letter-icon-title">@integradesign</a>
                    <div class="text-muted font-size-sm"><span class="badge badge-mark border-blue mr-1"></span>
                      Active</div>
                  </div>
                </div>
              </div>
              <div class="col-md-7">
                <div class="card_content">
                  <h3><a href="">lamaran sebagai iOS Developer (3284527)</a></h3>
                  <h4><a href="">instagram.com</a></h4>
                  <p>
                    lamaran sebagai iOS Developer (3284527) di WEBARQ (PT Web Architect Technology)! Cari lowongan
                    kerja
                  </p>
                </div>
              </div>
              <div class="col-md-2">
                <a href="#" class="text-default">
                  <span class="text-muted"><i class="icon-calendar mr-2"></i> 21 Sep 2020, 15:00</span>
                </a>
                <div class="row">
                  <div class="col-md-12 mt-3">
                    <div class="total_share">
                      <ul>
                        <li><a href=""><i class="icon-share3"></i> 1,383 </a></li>
                        <li><a href=""><i class="icon-thumbs-up2"></i> 1,383 </a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card_mention">
            <div class="row">
              <div class="col-md-3">
                <div class="d-flex align-items-center">
                  <div class="mr-3">
                    <a href="#" class="btn bg_tw  rounded-round btn-icon btn-sm">
                      <i class="icon-twitter"></i>
                    </a>
                  </div>
                  <div>
                    <a href="#" class="text-default font-weight-bold letter-icon-title">@integradesign</a>
                    <div class="text-muted font-size-sm"><span class="badge badge-mark border-blue mr-1"></span>
                      Active</div>
                  </div>
                </div>
              </div>
              <div class="col-md-7">
                <div class="card_content">
                  <h3><a href="">lamaran sebagai iOS Developer (3284527)</a></h3>
                  <h4><a href="">twitter.com</a></h4>
                  <p>
                    lamaran sebagai iOS Developer (3284527) di WEBARQ (PT Web Architect Technology)! Cari lowongan
                    kerja
                  </p>
                </div>
              </div>
              <div class="col-md-2">
                <a href="#" class="text-default">
                  <span class="text-muted"><i class="icon-calendar mr-2"></i> 21 Sep 2020, 15:00</span>
                </a>
                <div class="row">
                  <div class="col-md-12 mt-3">
                    <div class="total_share">
                      <ul>
                        <li><a href=""><i class="icon-share3"></i> 1,383 </a></li>
                        <li><a href=""><i class="icon-thumbs-up2"></i> 1,383 </a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card_mention">
            <div class="row">
              <div class="col-md-3">
                <div class="d-flex align-items-center">
                  <div class="mr-3">
                    <a href="#" class="btn bg_fb  rounded-round btn-icon btn-sm">
                      <i class="icon-facebook"></i>
                    </a>
                  </div>
                  <div>
                    <a href="#" class="text-default font-weight-bold letter-icon-title">@bobatimeindonesia</a>
                    <div class="text-muted font-size-sm"><span class="badge badge-mark border-blue mr-1"></span>
                      Active</div>
                  </div>
                </div>
              </div>
              <div class="col-md-7">
                <div class="card_content">
                  <h3><a href="">lamaran sebagai iOS Developer (3284527)</a></h3>
                  <h4><a href="">facebook.com</a></h4>
                  <p>
                    lamaran sebagai iOS Developer (3284527) di WEBARQ (PT Web Architect Technology)! Cari lowongan
                    kerja
                  </p>
                </div>
              </div>
              <div class="col-md-2">
                <a href="#" class="text-default">
                  <span class="text-muted"><i class="icon-calendar mr-2"></i> 21 Sep 2020, 15:00</span>
                </a>
                <div class="row">
                  <div class="col-md-12 mt-3">
                    <div class="total_share">
                      <ul>
                        <li><a href=""><i class="icon-share3"></i> 1,383 </a></li>
                        <li><a href=""><i class="icon-thumbs-up2"></i> 1,383 </a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card_mention">
            <div class="row">
              <div class="col-md-3">
                <div class="d-flex align-items-center">
                  <div class="mr-3">
                    <a href="#" class="btn bg_ig  rounded-round btn-icon btn-sm">
                      <i class="icon-instagram"></i>
                    </a>
                  </div>
                  <div>
                    <a href="#" class="text-default font-weight-bold letter-icon-title">@indosuryafinance</a>
                    <div class="text-muted font-size-sm"><span class="badge badge-mark border-blue mr-1"></span>
                      Active</div>
                  </div>
                </div>
              </div>
              <div class="col-md-7">
                <div class="card_content">
                  <h3><a href="">lamaran sebagai iOS Developer (3284527)</a></h3>
                  <h4><a href="">instagram.com</a></h4>
                  <p>
                    lamaran sebagai iOS Developer (3284527) di WEBARQ (PT Web Architect Technology)! Cari lowongan
                    kerja
                  </p>
                </div>
              </div>
              <div class="col-md-2">
                <a href="#" class="text-default">
                  <span class="text-muted"><i class="icon-calendar mr-2"></i> 21 Sep 2020, 15:00</span>
                </a>
                <div class="row">
                  <div class="col-md-12 mt-3">
                    <div class="total_share">
                      <ul>
                        <li><a href=""><i class="icon-share3"></i> 1,383 </a></li>
                        <li><a href=""><i class="icon-thumbs-up2"></i> 1,383 </a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card_mention">
            <div class="row">
              <div class="col-md-3">
                <div class="d-flex align-items-center">
                  <div class="mr-3">
                    <a href="#" class="btn bg_tw  rounded-round btn-icon btn-sm">
                      <i class="icon-twitter"></i>
                    </a>
                  </div>
                  <div>
                    <a href="#" class="text-default font-weight-bold letter-icon-title">@kemilaumarmer</a>
                    <div class="text-muted font-size-sm"><span class="badge badge-mark border-blue mr-1"></span>
                      Active</div>
                  </div>
                </div>
              </div>
              <div class="col-md-7">
                <div class="card_content">
                  <h3><a href="">lamaran sebagai iOS Developer (3284527)</a></h3>
                  <h4><a href="">twitter.com</a></h4>
                  <p>
                    lamaran sebagai iOS Developer (3284527) di WEBARQ (PT Web Architect Technology)! Cari lowongan
                    kerja
                  </p>
                </div>
              </div>
              <div class="col-md-2">
                <a href="#" class="text-default">
                  <span class="text-muted"><i class="icon-calendar mr-2"></i> 21 Sep 2020, 15:00</span>
                </a>
                <div class="row">
                  <div class="col-md-12 mt-3">
                    <div class="total_share">
                      <ul>
                        <li><a href=""><i class="icon-share3"></i> 1,383 </a></li>
                        <li><a href=""><i class="icon-thumbs-up2"></i> 1,383 </a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <b-card>
            <ul class="pagination pagination-flat align-self-center">
              <li class="page-item"><a href="#" class="page-link">&larr; &nbsp; Prev</a></li>
              <li class="page-item active"><a href="#" class="page-link">1</a></li>
              <li class="page-item"><a href="#" class="page-link">2</a></li>
              <li class="page-item disabled"><a href="#" class="page-link">3</a></li>
              <li class="page-item"><a href="#" class="page-link">4</a></li>
              <li class="page-item"><a href="#" class="page-link">Next &nbsp; &rarr;</a></li>
            </ul>
          </b-card>
        </b-tab>
      </b-tabs>
      <b-row>
        <b-col xl="4">
          <b-card no-body>
            <template #header>
              <b-card-title title-tag="h6" class="font-weight-bold">
                Most Active Social Media
              </b-card-title>
            </template>
            <div class="table-responsive">
              <table class="table table-bordered   text-nowrap">
                <thead>
                  <tr>
                    <th>Source</th>
                    <th>Mention</th>
                    <th>Reach</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div class="d-flex align-items-center">
                        <div class="mr-1">
                          <a href="#" class="btn bg_ig  rounded-round btn-icon btn-sm">
                            <i class="icon-instagram"></i>
                          </a>
                        </div>
                        <div>
                          <a href="#" class="text-default font-weight-semibold letter-icon-title">@rancangmebel</a>
                        </div>
                      </div>
                    </td>
                    <td><span class="font-weight-bold mb-0">20</span></td>
                    <td><span class="font-weight-bold mb-0">1500</span></td>
                  </tr>

                  <tr>
                    <td>
                      <div class="d-flex align-items-center">
                        <div class="mr-1">
                          <a href="#" class="btn bg_fb  rounded-round btn-icon btn-sm">
                            <i class="icon-facebook"></i>
                          </a>
                        </div>
                        <div>
                          <a href="#" class="text-default font-weight-semibold letter-icon-title">@kemilau marmer</a>
                        </div>
                      </div>
                    </td>
                    <td><span class="font-weight-bold mb-0">20</span></td>
                    <td><span class="font-weight-bold mb-0">1500</span></td>
                  </tr>

                  <tr>
                    <td>
                      <div class="d-flex align-items-center">
                        <div class="mr-1">
                          <a href="#" class="btn bg_tw  rounded-round btn-icon btn-sm">
                            <i class="icon-twitter"></i>
                          </a>
                        </div>
                        <div>
                          <a href="#" class="text-default font-weight-semibold letter-icon-title">@kemilau marmer</a>
                        </div>
                      </div>
                    </td>
                    <td><span class="font-weight-bold mb-0">20</span></td>
                    <td><span class="font-weight-bold mb-0">1500</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <template #footer>
              <ul class="pagination pagination-flat align-self-center">
                <li class="page-item"><a href="#" class="page-link">&larr; &nbsp; Prev</a></li>
                <li class="page-item active"><a href="#" class="page-link">1</a></li>
                <li class="page-item"><a href="#" class="page-link">2</a></li>
                <li class="page-item disabled"><a href="#" class="page-link">3</a></li>
                <li class="page-item"><a href="#" class="page-link">4</a></li>
                <li class="page-item"><a href="#" class="page-link">Next &nbsp; &rarr;</a></li>
              </ul>
            </template>
          </b-card>
        </b-col>
        <b-col xl="4">
          <b-card no-body>
            <template #header>
              <b-card-title title-tag="h6" class="font-weight-bold">
                Most Active Location
              </b-card-title>
            </template>
            <div class="table-responsive">
              <table class="table table-bordered  text-nowrap">
                <thead>
                  <tr>
                    <th>Source</th>
                    <th>Mention</th>
                    <th>Reach</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div class="d-flex align-items-center">
                        <div>
                          <a href="#" class="text-default font-weight-semibold letter-icon-title">cnnindonesia.com</a>
                        </div>
                      </div>
                    </td>
                    <td><span class="font-weight-bold mb-0">20</span></td>
                    <td><span class="font-weight-bold mb-0">1500</span></td>
                  </tr>

                  <tr>
                    <td>
                      <div class="d-flex align-items-center">
                        <div>
                          <a href="#" class="text-default font-weight-semibold letter-icon-title">rancangmebel.com</a>
                        </div>
                      </div>
                    </td>
                    <td><span class="font-weight-bold mb-0">20</span></td>
                    <td><span class="font-weight-bold mb-0">1500</span></td>
                  </tr>

                  <tr>
                    <td>
                      <div class="d-flex align-items-center">
                        <div>
                          <a href="#" class="text-default font-weight-semibold letter-icon-title">opini.id</a>
                        </div>
                      </div>
                    </td>
                    <td><span class="font-weight-bold mb-0">20</span></td>
                    <td><span class="font-weight-bold mb-0">1500</span></td>
                  </tr>

                  <tr>
                    <td>
                      <div class="d-flex align-items-center">
                        <div>
                          <a href="#" class="text-default font-weight-semibold letter-icon-title">lingkar9.com</a>
                        </div>
                      </div>
                    </td>
                    <td><span class="font-weight-bold mb-0">20</span></td>
                    <td><span class="font-weight-bold mb-0">1500</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <template #footer>
              <ul class="pagination pagination-flat align-self-center">
                <li class="page-item"><a href="#" class="page-link">&larr; &nbsp; Prev</a></li>
                <li class="page-item active"><a href="#" class="page-link">1</a></li>
                <li class="page-item"><a href="#" class="page-link">2</a></li>
                <li class="page-item disabled"><a href="#" class="page-link">3</a></li>
                <li class="page-item"><a href="#" class="page-link">4</a></li>
                <li class="page-item"><a href="#" class="page-link">Next &nbsp; &rarr;</a></li>
              </ul>
            </template>
          </b-card>
        </b-col>
        <b-col xl="4">
          <b-card no-body>
            <template #header>
              <b-card-title title-tag="h6" class="font-weight-bold">
                Most Active Location
              </b-card-title>
            </template>
            <div class="table-responsive">
              <table class="table table-bordered   text-nowrap">
                <thead>
                  <tr>
                    <th>Source</th>
                    <th>Mention</th>
                    <th>Reach</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div class="d-flex align-items-center">

                        <div>
                          <a href="#" class="text-default font-weight-semibold letter-icon-title">Jakarta</a>
                        </div>
                      </div>
                    </td>
                    <td><span class="font-weight-bold mb-0">20</span></td>
                    <td><span class="font-weight-bold mb-0">1500</span></td>

                  </tr>
                  <tr>
                    <td>
                      <div class="d-flex align-items-center">

                        <div>
                          <a href="#" class="text-default font-weight-semibold letter-icon-title">Surabaya</a>
                        </div>
                      </div>
                    </td>
                    <td><span class="font-weight-bold mb-0">20</span></td>
                    <td><span class="font-weight-bold mb-0">1500</span></td>

                  </tr>
                  <tr>
                    <td>
                      <div class="d-flex align-items-center">

                        <div>
                          <a href="#" class="text-default font-weight-semibold letter-icon-title">Bandung</a>
                        </div>
                      </div>
                    </td>
                    <td><span class="font-weight-bold mb-0">20</span></td>
                    <td><span class="font-weight-bold mb-0">1500</span></td>

                  </tr>

                  <tr>
                    <td>
                      <div class="d-flex align-items-center">

                        <div>
                          <a href="#" class="text-default font-weight-semibold letter-icon-title">Bogor</a>
                        </div>
                      </div>
                    </td>
                    <td><span class="font-weight-bold mb-0">20</span></td>
                    <td><span class="font-weight-bold mb-0">1500</span></td>

                  </tr>
                </tbody>
              </table>
            </div>
            <template #footer>
              <ul class="pagination pagination-flat align-self-center">
                <li class="page-item"><a href="#" class="page-link">&larr; &nbsp; Prev</a></li>
                <li class="page-item active"><a href="#" class="page-link">1</a></li>
                <li class="page-item"><a href="#" class="page-link">2</a></li>
                <li class="page-item disabled"><a href="#" class="page-link">3</a></li>
                <li class="page-item"><a href="#" class="page-link">4</a></li>
                <li class="page-item"><a href="#" class="page-link">Next &nbsp; &rarr;</a></li>
              </ul>
            </template>
          </b-card>
        </b-col>
      </b-row>
      <b-card>
        <template #header>
          <b-card-title title-tag="h6" class="font-weight-bold">Most Mentioned Term</b-card-title>
        </template>
        <div class="wrap_most_term">
          <ul>
            <li>Custom</li>
            <li class="middle">Lemari</li>
            <li>Harga</li>
            <li class="small">Cari</li>
            <li>Set</li>
            <li>Barangan</li>
            <li>Marmer</li>
            <li class="middle">Bantal</li>
            <li>Pesan</li>
            <li>Kursi</li>
            <li class="small">Bantal</li>
            <li class="big">Kitchen</li>
            <li>Harga</li>
            <li>Cari</li>
            <li>Set</li>
            <li>Barangan</li>

            <li class="big">Custom</li>
            <li>Marmer</li>
            <li>Bantal</li>
            <li class="small">Pesan</li>
            <li>Kursi</li>
            <li>Bantal</li>

            <li>Custom</li>
            <li class="middle">Lemari</li>
            <li>Harga</li>
            <li class="small">Cari</li>
            <li>Set</li>
            <li>Barangan</li>
            <li>Marmer</li>

          </ul>
        </div>
      </b-card>
    </div>
    <bo-footer></bo-footer>
  </div>
</template>
<script>
import Vue from 'vue'
import tableDatas from '@/dummies/tableDatas'
import GlobalVue from '../../libs/Global.vue'
import Sparkline from 'vue-sparklines'

Vue.use(Sparkline)

export default {
  extends: GlobalVue,
  data() {
    return {
      dateRange: {},
      spData1: (() => {
        const len = 50
        return Array.from({
          length: len
        }, () => Math.floor(Math.random() * len))
      })(),
      // 指示器样式
      spIndicatorStyles1: false,
      // 折线样式
      spLineStyles1: {
        stroke: 'rgb(84, 165, 255)'
      },
      sourceMentionFields: tableDatas.sourceMentionFields,
      sourceMentionDatas: tableDatas.sourceMentionDatas,
      sourceMentionPage: 1,
    }
  }
}
</script>